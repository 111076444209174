import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  getCurrentInstance,
  onBeforeUnmount,
} from "vue";
import apiService from "../api/services";
import store from "../store/index";
import moment from "moment-timezone";
import Worker from "worker-loader!../workers/repaly/worker.js?name=replay";
console.log("in usereplay");

const isAuto = ref(true);
let completeData = reactive({});
let mnemonic_groups = ref([]);
let wellInfo = ref(null);
const replayDetails = ref(null);
const counter = ref(0);
let minMax = ref(null);
let timeInterval = ref(null);
let startTDate = ref(0);
let endDate = ref(0);
let hours = ref(1);
let nextDataOReplay = reactive({});
let loadNext = ref(true);
let numberOfRecord = ref(60);
let timeIntervalValue = ref(1500);
let worker = null;
export function useReplay() {
  const { proxy } = getCurrentInstance();
  if (!worker) {
    worker = new Worker();
  }
  // const worker = new Worker();
  function setAutoPlay(val) {
    isAuto.value = val;
    worker.postMessage({
      data: JSON.stringify(val),
      type: "setAutoPlay",
    });
    // if (!val) {
    //   timeInterval.value = clearInterval(timeInterval.value);
    // } else {
    //   changeDetailByInterval();
    // }
  }
  async function setData(wellDetails, menomenics) {
    console.log("set data in usereplay");
    isAuto.value = true;
    numberOfRecord.value = 60;
    timeIntervalValue.value = 1500;
    store.dispatch("data/startLoading");
    worker.postMessage({
      wellDetails: JSON.stringify(wellDetails),
      menomenics: JSON.stringify(menomenics),
      type: "setData",
    });
    // if (timeInterval.value) {
    //   timeInterval.value = clearInterval(timeInterval.value);
    // }
    // completeData = {};
    // nextDataOReplay = {};
    // mnemonic_groups.value = menomenics;
    // wellInfo.value = wellDetails;

    // minMax.value = await getMinMaxRange(
    //   wellDetails.logType,
    //   wellDetails.wellId,
    //   wellDetails.wellboreId
    // );

    // isAuto.value = true;
    // loadNext.value = true;
    // startTDate.value = new Date(wellDetails.startTime).getTime();
    // await fetchDetailsFromDb();
  }
  async function fetchDetailsFromDb(auto = false, fetchType = "first") {
    store.dispatch("data/startLoading");

    timeInterval.value = clearInterval(timeInterval.value);

    const { wellId, wellboreId, startTime } = wellInfo.value;

    endDate.value = startTDate.value + 3600000 * hours.value;

    for (let i in mnemonic_groups.value) {
      if (i == "SOLUTION") {
        if (
          mnemonic_groups.value[i].indexOf("stickslipindex") > -1 &&
          mnemonic_groups.value[i].indexOf("stickslip") == -1
        )
          mnemonic_groups.value[i].push("stickslip");
        if (
          mnemonic_groups.value[i].indexOf("washoutindex") > -1 &&
          mnemonic_groups.value[i].indexOf("washout") == -1
        )
          mnemonic_groups.value[i].push("washout");

        if (mnemonic_groups.value[i].indexOf("time") == -1)
          mnemonic_groups.value[i].push("time");
      }

      let result = await apiService.WellServices.getTimeLogs({
        fields: mnemonic_groups.value[i],
        well_id: wellId,
        wellbore_name: wellboreId,
        isParse: 1,
        type: i,
        // days: 0.2,
        from: endDate.value,
        to: startTDate.value,
      });

      completeData = { ...completeData, ...result.data.data };
    }

    setTimeout(() => {
      store.dispatch("data/stopLoading");
      if (completeData && completeData["time"].length > 0) {
        changeDetailByInterval();
      } else {
        isAuto.value = false;
      }
    }, 500);
  }
  async function changeDetailByInterval() {
    try {
      timeInterval.value = setInterval(async () => {
        let data = {};
        for (const property in completeData) {
          data[property] = chunkArray(
            completeData[property],
            numberOfRecord.value
          );
        }
        replayDetails.value = data;
        counter.value += 1;

        if (
          timeInterval.value &&
          completeData &&
          completeData["time"] &&
          completeData["time"].length == 0
        ) {
          timeInterval.value = clearInterval(timeInterval.value);
          if (isAuto.value) {
            startTDate.value += 3600000 * hours.value;
            if (startTDate.value < minMax.value.max) {
              console.log("next iteration::", wellInfo.value);
              fetchDetailsFromDb();
              console.log("next iteration loadNext.value::", loadNext.value);
            } else {
              isAuto.value = false;
            }
          }
        }
      }, timeIntervalValue.value);
    } catch (error) {
      timeInterval.value = clearInterval(timeInterval.value);
    }
  }
  function chunkArray(array, chunkSize, index = 0) {
    if (index >= array.length) {
      return;
    }
    const chunk = array.slice(index, index + chunkSize);
    array.splice(index, index + chunkSize);
    return chunk;
  }
  async function getMinMaxRange(logType, wellId, wellboreId) {
    try {
      let result = null;
      result = await apiService.WellServices.getMinMaxTime({
        well_id: wellId,
        wellbore_name: wellboreId,
      });
      if (logType == "time") {
        result.data.min = moment(result.data.min).unix() * 1000;
        result.data.max = moment(result.data.max).unix() * 1000;
      } else {
        result.data.min = result.data.min;
        result.data.max = result.data.max;
      }
      console.log("result minmax:", result);
      return result.data;
    } catch (error) {
      console.error(error);
      return { min: 0, max: 1000 };
    }
  }
  function removeruningIntervals() {
    if (timeInterval.value) {
      timeInterval.value = clearInterval(timeInterval.value);
    }
  }
  async function changeDateReplay(minDate) {
    // startTDate.value = minDate;
    // loadNext.value = true;
    // fetchDetailsFromDb(true);
    worker.postMessage({
      data: JSON.stringify(minDate),
      type: "changeDateReplay",
    });
  }
  async function changeHouesReplay(hour) {
    hours.value = +hour;
    worker.postMessage({
      data: JSON.stringify(hour),
      type: "changeHouesReplay",
    });
    // loadNext.value = true;
    // if (timeInterval.value) {
    //   timeInterval.value = clearInterval(timeInterval.value);
    // }
    // fetchDetailsFromDb(true);
  }
  async function changeNumberOfrecordTobeDisplay(num) {
    // const { well_interval } = wellInfo.value;
    numberOfRecord.value = +num;

    // if (numberOfRecord.value == 900) {
    //   timeIntervalValue.value = 2000;
    //   // if(well_interval && well_interval=='5_sec') numberOfRecord.value = +num/6
    //   if (timeInterval.value) {
    //     timeInterval.value = clearInterval(timeInterval.value);
    //     changeDetailByInterval();
    //   }
    // } else if (numberOfRecord.value == 1800) {
    //   timeIntervalValue.value = 3000;
    //   // if(well_interval && well_interval=='5_sec') numberOfRecord.value = +num/6
    //   if (timeInterval.value) {
    //     timeInterval.value = clearInterval(timeInterval.value);
    //     changeDetailByInterval();
    //   }
    // }
    worker.postMessage({
      data: JSON.stringify(num),
      type: "changeNumberOfrecordTobeDisplay",
    });
  }

  if (worker) {
    worker.onmessage = function (e) {
      if (e.data.type == "batchData") {
        console.log("batchData");
        store.dispatch("data/stopLoading");
        replayDetails.value = JSON.parse(e.data.data);
      } else if (e.data.type == "completed") {
        const curentVal = JSON.parse(e.data.data);
        console.log("completed::", curentVal);
        isAuto.value = curentVal;
        proxy.$toast.error("Well reaced to end", {
          duration: 2000,
          position: "top",
          pauseOnHover: true,
        });
      } else if (e.data.type == "nodatafound") {
        console.log("nodatafound::");
        isAuto.value = false;
        proxy.$toast.error("No data found for selected date!", {
          duration: 3000,
          position: "top",
          pauseOnHover: true,
        });
      }
    };
  }

  onMounted(() => {
    console.log("Mounted usereplay comosable::");
  });
  onBeforeUnmount(() => {
    store.dispatch("data/stopLoading");
    if (worker) {
      worker.terminate();
    }
    console.log("onBeforeUnmount usereplay comosable::");
  });
  onUnmounted(() => {
    worker = null;
    console.log("Unmounted usereplay comosable::");
  });
  return {
    isAuto,
    setAutoPlay,
    setData,
    replayDetails,
    counter,
    removeruningIntervals,
    changeDateReplay,
    changeHouesReplay,
    changeNumberOfrecordTobeDisplay,
  };
}
