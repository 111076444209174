import Axios from "axios";
import constant from "../constant";
import api from "../dictionary/index";

export default {
    //JAVA
    get_actc_list: async () => {
        try {
            let response = await Axios.get(constant.DATASERVICES_URL + api.actc.ACTC_LIST())
            return response
        } catch (error) {
            return error
        }
    },
    actc_save: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.actc.ACTC_SAVE(), payload)
            return response
        } catch (error) {
            return error
        }
    },

    //NODE
    actc_search: async (payload) => {
        try {
            let response = await Axios.get(constant.BASE_URL + api.actc.ACTC_SEARCH(), {params: payload})
            return response
        } catch (error) {
            return error
        }
    },
    clear_from_lokidb: async (payload) => {
        try {
            let response = await Axios.get(constant.BASE_URL + api.actc.CLEAR_FROM_LOKIDB(), {params: payload})
            return response
        } catch (error) {
            return error
        }
    },
}