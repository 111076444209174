import Axios from "axios";
import constant from "../constant";
import api from "../dictionary/index";

export default {
  gettingUsersRelatedToEntity: async (data) => {
    let response = await Axios.post(
      constant.DATASERVICES_URL + api.support.GETTING_USERS_RELATED_TO_ENTITY(),
      data
    );
    return response;
  },
  sendingSmsToSelectedUsers: async (data) => {
    let response = await Axios.post(
      constant.BASE_URL + api.support.SENDING_SMS(),
      data
    );
    return response;
  },
  sendingEmailToselectedUsers: async (data) => {
    let response = await Axios.post(
      constant.BASE_URL + api.support.SENDING_EMAIL(),
      data
    );
    return response;
  },
  getSubEntity: async () => {
    try {
      let subEntity = await Axios.get(
        constant.DATASERVICES_URL + "entity/list"
      );
      return subEntity;
    } catch (error) {
      return error;
    }
  },
  getSingleUser: async (id) => {
    try {
      let singleUser_detail = await Axios.post(
        constant.DATASERVICES_URL + "users/search",
        {
          userId: id,
        }
      );
      return singleUser_detail;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getSingleSubEntity: async (id) => {
    try {
      let singleSubentity = await Axios.post(
        `${constant.DATASERVICES_URL}entity/search`,
        { entityId: id }
      );
      // userLoggedDetails = singleSubentity.data;

      return singleSubentity.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getUsersEnitity: async (id) => {
    try {
      let user_details = await Axios.post(
        constant.DATASERVICES_URL + "users/search",
        {
          entityId: id,
        }
      );
      return user_details;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
};
